import { gql, useQuery } from "@apollo/client";
import { Card, Text, BlockStack, Button, Layout, Page, Banner, Badge, InlineStack, SkeletonPage, Loading, SkeletonDisplayText, MediaCard } from "@shopify/polaris";
import React from "react";
import { useNavigate } from "react-router-dom";
import { GetSettingsValidQuery } from "../../../generated/gql/graphql";
import { ErrorPage } from "../../app/errors";
import { handleReturnToShopify } from "../../util/redirects";
import dashboardLogo from '../../assets/hpp-2x.png';

export const GET_SETTINGS_VALID = gql`
    query GetSettingsValid {
        settings {
            liveSettings {
                valid
                truelayerClientId
            }
            testSettings {
                valid
                truelayerClientId
            }
        }
    }
`;

type SettingsState = 'valid' | 'invalid' | 'not-configured';
const determineSettingsState = (settings: GetSettingsValidQuery['settings']['liveSettings']): SettingsState => {
    if (settings.valid) {
        return 'valid';
    }
    if (settings.truelayerClientId) {
        return 'invalid';
    }
    return 'not-configured';
};

interface SettingsStateBadgeProps {
    state: SettingsState
}
const SettingsStateBadge: React.FC<SettingsStateBadgeProps> = (props) => {
    const { state } = props;
    if (state == 'valid') {
        return (
            <Badge tone="success">
                OK
            </Badge>
        );
    }
    if (state == 'invalid') {
        return (
            <Badge tone="critical">
                Setup incomplete
            </Badge>
        );
    }
    return (
        <Badge tone="critical">
            Not set up yet
        </Badge>
    );
}

export const Dashboard: React.FC = () => {
    const navigate = useNavigate();

    const { data, loading: loading, error } = useQuery<GetSettingsValidQuery>(GET_SETTINGS_VALID);

    if (loading) {
        return (
            <SkeletonPage title='Dashboard'>
                <Loading />
                <SkeletonDisplayText />
            </SkeletonPage>
        );
    }

    if (!data || error) {
        return (
            <ErrorPage error={error} />
        );
    }

    const { testSettings, liveSettings } = data.settings;

    const testSettingsState = determineSettingsState(testSettings);
    const liveSettingsState = determineSettingsState(liveSettings);

    return (
        <Page narrowWidth>
            <Layout>
                <Layout.Section>
                    <MediaCard
                        title="Getting started"
                        description="Follow our step-by-step guide to get started with Truelayer on Shopify."
                        primaryAction={{
                            content: 'View our setup guide',
                            url: 'https://docs.truelayer.com/docs/shopify',
                            external: true,
                        }}
                        size="small"
                    >
                        <img
                            width="100%"
                            height="100%"
                            style={{
                                objectFit: 'cover',
                                objectPosition: 'center',
                            }}
                            src={dashboardLogo}
                        />
                    </MediaCard>
                </Layout.Section>
                <Layout.Section>
                    <Banner
                        tone="info"
                        title="Toggling test mode"
                        action={{
                            content: 'Payments settings',
                            onAction: () => handleReturnToShopify()
                        }}
                    >
                        <p>
                            Test mode is controlled via your Shopify payments settings.
                            When test mode is enabled, your sandbox settings
                            will be used instead of your live settings.
                        </p>
                    </Banner>
                </Layout.Section>
                <Layout.Section>
                    <Card>
                        <BlockStack gap="400" align="start">
                            <InlineStack gap="400">
                                <Text variant="headingMd" as="h3">
                                    Live settings
                                </Text>
                                <SettingsStateBadge state={liveSettingsState} />
                            </InlineStack>
                            <p>
                                You haven't linked your live Truelayer account to Shopify yet.
                            </p>
                            <Button onClick={() => navigate('/settings/live')}>
                                Configure live credentials
                            </Button>
                        </BlockStack>
                    </Card>
                </Layout.Section>
                <Layout.Section>
                    <Card>
                        <BlockStack gap="400" align="start">
                            <InlineStack gap="400">
                                <Text variant="headingMd" as="h3">
                                    Sandbox settings
                                </Text>
                                <SettingsStateBadge state={testSettingsState} />
                            </InlineStack>
                            <p>
                                You haven't linked your sandbox Truelayer account to Shopify yet.
                            </p>
                            <Button onClick={() => navigate('/settings/test')}>
                                Configure sandbox credentials
                            </Button>
                        </BlockStack>
                    </Card>
                </Layout.Section>
            </Layout>
        </Page>
    );
};