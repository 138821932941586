import { BlockStack, Banner, Collapsible, Divider, EmptyState, InlineStack, Link, Page, Scrollable } from "@shopify/polaris";
import React, { useId, useState } from "react";

export interface ErrorNoticeProps {
    error?: Error;
}
export const ErrorNotice: React.FC<ErrorNoticeProps> = (props) => {
    const { error } = props;
    const [open, setOpen] = useState(false);
    const id = useId();

    return (
        <Banner tone="warning">
            <BlockStack gap="400">
                <InlineStack align='space-between' wrap={false} gap='500'>
                    <span>
                        Something went wrong. Please try again in a few minutes, or contact support.
                    </span>
                    {error ? (
                        <Link onClick={() => setOpen(!open)}>
                            <span style={{whiteSpace: 'nowrap'}}>
                                {open ? 'Hide error details' : 'Show error details'}
                            </span>
                        </Link>
                    ) : undefined}
                </InlineStack>
                {error ? (
                    <Collapsible id={id} open={open}>
                        <div style={{ position: 'relative' }}>
                            <Scrollable style={{height: '300px'}}>
                                <pre style={{position: 'absolute'}}>
                                    <>
                                        {error.toString()}
                                        {'\n'}
                                        {JSON.stringify(error, undefined, 2)}
                                    </>
                                </pre>
                            </Scrollable>
                        </div>
                    </Collapsible>
                ) : undefined}
            </BlockStack>
        </Banner>
    );
};

export interface ErrorPageProps {
    error?: Error;
}

export const ErrorPage: React.FC<ErrorPageProps> = (props) => {
    const { error } = props;
    const [open, setOpen] = useState(false);
    const id = useId();

    return (
        <Page>
            <EmptyState 
                heading='Something went wrong'
                action={{
                    content: 'Reload page',
                    onAction: () => window.location.reload(),
                }}
                image=''
                fullWidth
            >
                <BlockStack gap="400" align="start">
                    <span>
                        Please try again in a few minutes, or contact support.
                    </span>
                    {error ? (
                        <>
                            <Link onClick={() => setOpen(!open)}>
                                <span style={{ whiteSpace: 'nowrap' }}>
                                    {open ? 'Hide error details' : 'Show error details'}
                                </span>
                            </Link>
                            <div style={{ width: '100%', textAlign: 'left' }}>
                                <Collapsible id={id} open={open}>
                                    <div style={{ position: 'relative', background: 'white', border: '1px solid #ccc', padding: '4px' }}>
                                        <Scrollable style={{ height: '300px' }}>
                                            <pre style={{ position: 'absolute' }}>
                                                <>
                                                    {error.toString() + '\n'}
                                                    {'\n'}
                                                    {JSON.stringify(error, undefined, 2)}
                                                </>
                                            </pre>
                                        </Scrollable>
                                    </div>
                                </Collapsible>
                            </div>
                        </>
                    ) : undefined}
                </BlockStack>
            </EmptyState>
        </Page>
    );
}