import { gql, useQuery } from "@apollo/client";
import { Text, BlockStack, Divider, Scrollable, ResourceList, ResourceItem, Button, InlineStack, Tag, Checkbox, Thumbnail, Box, Banner, Loading } from "@shopify/polaris";
import React from "react";
import { EnvironmentSettingsInput, GetProvidersQuery, ProviderEntryInfo } from "../../../generated/gql/graphql";
import { ErrorNotice } from "../../app/errors";

export const GET_PROVIDERS = gql`
    query GetProviders {
        providers {
            test {
                id
                logoUrl
                iconUrl
                displayName
                country
            }
            live {
                id
                logoUrl
                iconUrl
                displayName
                country
            }
        }
    }
`;

export interface ProviderSettingsProps {
    settings: EnvironmentSettingsInput;
    test?: boolean;
    onChange: (v: EnvironmentSettingsInput) => void;
}
export const ProviderSettings: React.FC<ProviderSettingsProps> = (props) => {
    const { test, settings, onChange } = props;

    const { loading: loading, error, data } = useQuery<GetProvidersQuery>(GET_PROVIDERS, {
        onError: (e) => {
            console.log('onerror', e);
        },
    });

    if (error) {
        return (
            <ErrorNotice error={error}/>
        );
    }

    const isDenied = (provider: ProviderEntryInfo) => {
        return settings.providerDenylistFilter.indexOf(provider.id) > -1;
    };

    const updateDenied = (provider: ProviderEntryInfo, denied: boolean) => {
        if (denied) {
            onChange({
                ...settings,
                providerDenylistFilter: [
                    ...settings.providerDenylistFilter,
                    provider.id,
                ]
            });
        } else {
            onChange({
                ...settings,
                providerDenylistFilter: settings.providerDenylistFilter.filter((p) => p != provider.id),
            });
        }
    };

    const renderProvider = (provider: ProviderEntryInfo) => (
        <Box key={provider.id} padding="200">
            <InlineStack blockAlign="center" align="space-between">
                {isDenied(provider) ? (
                    <>
                        <InlineStack gap="200">
                            <Thumbnail 
                                size="small"
                                source={provider.iconUrl || provider.logoUrl} alt={""}
                            />
                            <Checkbox 
                                label={provider.displayName} 
                                onChange={() => updateDenied(provider, false)}
                            />
                        </InlineStack>
                        <Tag>
                            Disabled
                        </Tag>
                    </>
                ) : (
                    <>
                        <InlineStack gap="200">
                            <Thumbnail 
                                size="small"
                                source={provider.logoUrl || provider.iconUrl} alt={""}
                            />
                            <Checkbox 
                                checked
                                label={provider.displayName} 
                                onChange={() => updateDenied(provider, true)}
                            />
                        </InlineStack>
                    </>
                )}
            </InlineStack>
        </Box>
    );

    const providers = (test ? data?.providers.test : data?.providers.live) || [];

    if (error) {
        return (
            <ErrorNotice error={error}/>
        );
    }

    if (loading) {
        return (
            <Loading/>
        );
    }

    if (providers.length <= 0) {
        return (
            <BlockStack align="start" gap="400">
                <BlockStack gap="400">
                    <Text variant='headingSm' as='p'>
                        Allowed providers 
                        ({providers.length - settings.providerDenylistFilter.length})
                    </Text>
                    <p>Customize which providers will be offered to customers. Note that new providers will be allowed by default.</p>
                    <Banner tone='warning'>
                        Connect your Truelayer account by providing credentials above to view which providers are available to you.
                    </Banner>
                </BlockStack>
            </BlockStack>
        );
    }

    return (
        <BlockStack align="start" gap="400">
            <BlockStack gap="400">
                <Text variant='headingSm' as='p'>
                    Allowed providers 
                    ({providers.length - settings.providerDenylistFilter.length})
                </Text>
                <p>Customize which providers will be offered to customers. Note that new providers will be allowed by default.</p>
                <Scrollable shadow style={{ height: '300px', width: '100%', border: '1px solid #ccc' }}>
                    {providers.map((p) => renderProvider(p))}
                </Scrollable>
            </BlockStack>
        </BlockStack>
    );
};