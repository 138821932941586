import { Outlet, RouterProvider, Routes, useLocation } from "react-router-dom";
import { ArrowLeftIcon, ExternalIcon } from "@shopify/polaris-icons";
import { FooterHelp, Frame, Link, Navigation, TopBar } from "@shopify/polaris";
import tlLogoCharcoal from '../assets/TrueLayer-LOGO-charcoal-transp-horizontal-fullbleed.png';
import tlLogowhite from '../assets/TrueLayer-LOGO-white-transp-horizontal-fullbleed.png';
import { handleReturnToShopify } from "../util/redirects";

export const AppFrame = () => {
    const location = useLocation();

    const navigation = (
        <Navigation location={location.pathname}>
            <Navigation.Section
                items={[
                    {
                        label: 'Back to Shopify',
                        icon: ArrowLeftIcon,
                        onClick: handleReturnToShopify,
                    }
                ]}
            />
            <Navigation.Section
                separator
                items={[
                    {
                        label: 'Summary',
                        url: '/',
                        exactMatch: true,
                    },
                    {
                        label: 'Live settings',
                        url: '/settings/live',
                    },
                    {
                        label: 'Sandbox settings',
                        url: '/settings/test',
                    },
                ]}
            />
            <Navigation.Section
                separator
                items={[
                    {
                        label: 'Truelayer console',
                        url: 'https://console.truelayer.com/',
                        external: true,
                        icon: ExternalIcon,
                    },
                ]}
            />
        </Navigation>
    );

    const topBar = (
        <TopBar 
        />
    );

    return (
        <Frame 
            navigation={navigation}
            topBar={topBar}
            logo={{
                topBarSource: tlLogoCharcoal,
                contextualSaveBarSource: tlLogowhite,
            }}
        >
            <Outlet/>
            <FooterHelp>
                <Link url='#'>
                    Contact Truelayer
                </Link>
                {' '}
                for help &amp; support
            </FooterHelp>
        </Frame>
    );
};